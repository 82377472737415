import styled from 'styled-components';
import Image from 'next/image';
import HomeOffer from 'public/home_offer.jpeg';

import { Col, Container, Row } from 'styled-bootstrap-grid';

import { BrandSectionWrapper } from './BrandSection/BrandSection.styled';

type Props = {};

const StyledCol = styled(Col)`
  height: 250px;
  padding: 0 10px;

  @media (min-width: 992px) {
    height: 350px;
  }
`;

export default function HomeOffers({}: Props) {
  return (
    <BrandSectionWrapper>
      <Container>
        <Row>
          <StyledCol col={12}>
            <div style={{ display: 'relative' }}>
              <Image src={HomeOffer} alt="" layout="fill" objectFit="contain" />
            </div>
          </StyledCol>
        </Row>
      </Container>
    </BrandSectionWrapper>
  );
}
