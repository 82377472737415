
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import BestCompaniesSection from '@Components/LandingPage/BestCompaniesSection';
import BestSellerSection from '@Components/LandingPage/BestSellerSection';
import BrandSection from '@Components/LandingPage/BrandSection';
import CategorySection from '@Components/LandingPage/CategorySection';
import FilterSection from '@Components/LandingPage/FilterSection';
import HeroSection from '@Components/LandingPage/HeroSection';
import HomeOffers from '@Components/LandingPage/HomeOffers';
import OffersOfTheDay from '@Components/LandingPage/OffersOfTheDay';
import Layout from '@Components/Layout';
import { _GetCategories } from '@Services/Categories';
import { _GetProducts } from '@Services/Products';
import { K_AllProducts, K_Categories } from '@Utils/ReactQuery.keys';
import { configureAxiosLocale } from 'lib/Axios';
import ReactQueryConfig from 'lib/ReactQueryConfig';
import type { GetStaticPropsContext, NextPage } from 'next';
import { generatePageSeo } from 'next-seo.config';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import React from 'react';
import { dehydrate, QueryClient } from 'react-query';

interface Props {}

const Home: NextPage<Props> = ({}) => {
  const { t, lang } = useTranslation('common');
  const { asPath } = useRouter();

  return (
    <Layout
      seoData={generatePageSeo({
        title: t('yourparts-slogan'),
        asPath,
        lang,
      })}
    >
      <HeroSection />
      <HomeOffers />
      {/* <BlackFridayOffers /> */}
      <FilterSection />
      <BrandSection />
      <OffersOfTheDay />
      <CategorySection />
      {/* <OurServiceSection /> */}
      <BestSellerSection />
      <BestCompaniesSection />
      {/* <DownloadSection /> */}
    </Layout>
  );
};

 async function _getStaticProps({ locale }: GetStaticPropsContext) {
  configureAxiosLocale(locale);
  try {
    const queryClient = new QueryClient(ReactQueryConfig);
    await queryClient.prefetchQuery(
      K_AllProducts({
        // special_price__isnull: false,
        limit: 3,
        locale,
      }),
      () =>
        _GetProducts({
          // special_price__isnull: false,
          limit: 3,
        })
    );
    await queryClient.prefetchQuery(
      K_AllProducts({
        best_seller: true,
        limit: 4,
        locale,
      }),
      () =>
        _GetProducts({
          best_seller: true,
          limit: 4,
        })
    );
    await queryClient.prefetchQuery(
      K_Categories({
        has_offer: true,
        locale,
      }),
      () =>
        _GetCategories({
          has_offer: true,
        })
    );
    await queryClient.prefetchQuery(
      K_Categories({
        is_top: true,
        locale,
        limit: 10,
      }),
      () =>
        _GetCategories({
          is_top: true,
          limit: 10,
        })
    );

    return {
      props: {
        dehydratedState: dehydrate(queryClient),
      },
      revalidate: 60, // In seconds
    };
  } catch (error) {
    return {
      props: {},
    };
  }
}

export default Home;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  